import s from './Rewiew.module.scss';
import ava1 from '../../img/person/ava1.jpg'
import ava2 from '../../img/person/ava2.jpg'
import ava3 from '../../img/person/ava3.jpg'
import ava4 from '../../img/person/ava4.jpg'

const Rewiew = () => {
  return (
    <div className={s.rewiew}>
      <h2 className={s.title}>Отзывы наших клиентов</h2>
      <div className={s.container}>
        <div className={s.block}>
          <img src={ava1} className={s.ava} alt='person' />
          <p className={s.name}>Карина Острахова</p>
          <p>"Спасибо большое команде за отличную работу! Благодаря их усилиям, наше сообщество в ВКонтакте получила огромное количество новых подписчиков и стала гораздо активнее. Очень довольны результатом!"</p>
        </div>
        <div className={s.block}>
          <img src={ava2} className={s.ava} alt='person' />
          <p className={s.name}>Кирилл Воронцов</p>
          <p>"Профессиональный подход и результаты превзошли все наши ожидания! Наша группа стала заметно выделяться среди конкурентов благодаря работе с команией ADV медиа. Будем обращаться еще!"</p>
        </div>
        <div className={s.block}>
          <img src={ava3} className={s.ava} alt='person' />
          <p className={s.name}>Иван Иссаев</p>
          <p>"Очень благодарны команде за продвижение нашей группы в социальной сети ВКонтакте! Уже через короткое время мы заметили увеличение активности и вовлеченности подписчиков. Рекомендуем!"</p>
        </div>
        <div className={s.block}>
          <img src={ava4} className={s.ava} alt='person' />
          <p className={s.name}>Светлана Коринчук</p>
          <p>"Это было именно то, что нам нужно! Продвижение группы в ВКонтакте сделало наш бренд более узнаваемым и привлекло новую аудиторию. Отличная работа, спасибо!"</p>
        </div>
      </div>

      <div className={s.content}>
        <div className={s.block2}>
          <p className={s.name2}>Серей Златарев</p>
          <p>"Наше сообщество в ВКонтакте раньше стояло на месте, но после работы с этой командой мы заметили резкое увеличение числа подписчиков и активность в сообществе. Очень довольны результатом!"</p>
        </div>
        <div className={s.block2}>
          <p className={s.name2}>Ирина Коновал</p>
          <p>"Профессионализм и индивидуальный подход - вот что отличает эту команду! Они помогли нам разработать стратегию продвижения в социальной сети ВКонтакте, и результаты превзошли все наши ожидания! Мы получили больше клиентов чем могли себе думать, работая по тарифу мы получили более 300 клиентов, вместо обещанных 200. Рекомендуем!"</p>
        </div>
        <div className={s.block2}>
          <p className={s.name2}>Артем Поступов</p>
          <p>"Спасибо большое за продвижение нашей группы в ВКонтакте! Мы уже видим положительные изменения в активности аудитории и увеличение числа подписчиков. Будем рекомендовать вас всем нашим знакомым!"</p>
        </div>
        <div className={s.block2}>
          <p className={s.name2}>Дмитрий Серсеев</p>
          <p>"Наша компания долго искала профессионалов в продвижении группы в ВКонтакте, и мы нашли их! Благодаря работе этой команды наша группа стала гораздо более видимой и популярной. Очень довольны результатом!"</p>
        </div>
        <div className={s.block2}>
          <p className={s.name2}>Алина Кринова</p>
          <p>"Профессиональный подход, оперативность и отличные результаты! Мы довольны сотрудничеством и готовы рекомендовать эту команду всем, кто хочет эффективно продвигать свою группу в ВКонтакте."</p>
        </div>
      </div>
    </div>
  );
}

export default Rewiew;